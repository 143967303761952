import React, { useContext, useEffect, useState } from 'react';
import * as S from './SubLockInSelector.styles';
import FunnelContext from '../../../context/FunnelContext';
import Select from 'react-select';

export const SubLockInSelector = props => {
  const { show, items } = props;

  const { extraObjects, setExtraObjects } = useContext(FunnelContext);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded && show && items) {
      let initialValue = items.filter(item => item.default === true);
      initialValue =
        !initialValue || initialValue.length <= 0 ? items[0] : initialValue[0];
      initialValue = extraObjects?.sub_lock_in_schedule ?? initialValue.value;
      setExtraObjects(
        Object.assign(extraObjects, {
          sub_lock_in_enabled: true,
          sub_lock_in_schedule: initialValue
        })
      );
      return setLoaded(true);
    }
  }, [show, items, extraObjects, setExtraObjects, loaded, setLoaded]);

  const handleSelectChange = ({ value }) => {
    setExtraObjects(
      Object.assign(extraObjects, {
        sub_lock_in_enabled: true,
        sub_lock_in_schedule: value
      })
    );
  };

  if (loaded && show && items) {
    return (
      <S.Container>
        <S.Label htmlFor="sublockin-select-input">Lock-in Period</S.Label>
        <Select
          classNamePrefix="sublockin-select"
          options={items}
          onChange={handleSelectChange}
          inputProps={{ readOnly: true }}
          isSearchable={false}
          defaultValue={items.filter(
            option => option.value === extraObjects?.sub_lock_in_schedule
          )}
          inputId="sublockin-select-input"
        />
      </S.Container>
    );
  } else {
    return ``;
  }
};

export default SubLockInSelector;
